.CatalogBlock {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}
.block_btns {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
}
.btn {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
  z-index: 2;
  border: none;
  color: white;
  font-size: 15px;
  text-transform: capitalize;
  margin: 7px 0;
}
.btn .MuiChip-label {
  font-size: 16px !important;
  text-align: center;
}
.btn:hover .btn_on {
  opacity: 0;
}
.btn:hover .btn_off {
  opacity: 1;
}
.btn_on {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.3s;
}
.btn_off {
  transition: 0.3s;
  z-index: -1;
  opacity: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.categories-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.categories-block {
  width: 660px;
}

@media screen and (min-width: 2560px) {
  .block_btns {
    padding-top: 150px;
    width: 80%;
  }
}
@media screen and (max-width: 2560px) {
  .block_btns {
    padding-top: 150px;
    width: 80%;
  }
}
@media screen and (max-width: 1024px) {
  .CatalogPage {
    height: 1200px;
    width: 100%;
    margin-bottom: -500px;
  }
  .block_btns {
    width: 60%;
    padding-top: 80px;
  }
}
@media screen and (max-width: 992px) {
  .CatalogPage {
    height: 1150px;
  }
  .block_btns {
    width: 60%;
    padding: 30px;
  }
  .btn {
    font-size: 15px;
  }
  .img6 {
    width: 219px;
  }
  .img11 {
    width: 212px;
  }
}
@media screen and (max-width: 768px) {
  .CatalogPage {
    width: 135%;
    margin-left: -30%;
    padding-left: 15%;
  }
  .img11 {
    height: 210px !important;
  }
  .block_btns {
    width: 80%;
    padding: 150px;
    padding-top: 188px;
  }
  .btn {
    font-size: 14px !important;
  }
  .block_btns {
    margin-top: -100px;
  }
}
@media screen and (max-width: 576px) {
  .imgbtns {
    display: none;
  }
  .CatalogPage {
    margin-left: -42%;
    /*padding-left: 0;*/
    /*width: 100% !important;*/
    height: 100% !important;
    margin-bottom: 0;
  }
  .block_btns {
    position: relative;
  }
  .btn {
    height: 52px;
    position: relative;
    font-size: 16px !important;
    margin: 0 !important;
  }
  .css-12tpsb5-MuiButtonBase-root-MuiChip-root {
    height: 100% !important;
  }
  .categories-block {
    width: 100%;
  }
  .btn .MuiChip-root {
    padding: 8px 0px;
  }
}
@media screen and (max-width: 425px) {
  .css-jj7e1s-MuiGrid-root {
    justify-content: center !important;
  }
  .css-6od3lo-MuiChip-label {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 414px) {
  .btn {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 375px) {
  .btn {
    font-size: 16px !important;
  }
  .CatalogPage {
    margin-left: -46%;
    /*padding-left: 0;*/
    /*width: 100% !important;*/
    height: 100% !important;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 320px) {
  .btn {
    font-size: 15px !important;
  }
  .CatalogPage {
    margin-left: 0;
    width: 100%;
    /*padding-left: 0;*/
    /*width: 100% !important;*/
    height: 100% !important;
    margin-bottom: 0;
    padding-left: 0;
  }
  .block_btns {
    position: relative;
    width: 100px;
    padding: 0;
  }
}

.a {
  z-index: 3;
}

.c4,
.c6,
.c3,
.c10,
.c5,
.c2,
.c7,
.c8,
.c9 {
  opacity: 0;
}

.swiperVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  z-index: 1000;
  left: 0;
  top: 0;
}
