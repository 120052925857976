/*img poster*/
.poster_of_size {
  width: 40vw;
  position: relative;
  margin-bottom: 3%;
  overflow-y: scroll;
}
@media screen and (min-width: 1500px) {
  .poster_of_size {
  }
}
.poster_of_title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

/*module*/

.window_module {
  width: 40vw;
  height: 40.7vh;
  position: relative;
  margin-bottom: 3%;
  overflow-y: scroll;
}
.window_module_list span {
  font-size: 16px;
  font-weight: 400;
}
.window_module_list {
  margin-bottom: 2%;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3b3b42;
}
.window_module_list_block1 {
  position: relative;
  width: 25vw;
}
.window_module_list_block2 {
  position: relative;
  width: 8vw;
  display: flex;
  justify-content: end;
}
.window_module_list_block3 {
  position: relative;
  display: flex;
  justify-content: end;
  width: 7vw;
}
.window_module_list_block2 span {
  font-size: 16px;
  font-weight: 400;
  margin: 0 3px;
}

@media screen and (max-width: 768px) {
  .poster_of_size {
    width: 50vw;
  }
  .window_module {
    width: 80vw;
    height: 30.7vh;
  }
}
@media screen and (max-width: 425px) {
  .poster_of_size {
    width: 92vw;
  }
  .window_module {
    width: 92vw;
    height: 25vh;
  }
  .window_module_list span {
    font-size: 14px;
  }
  .window_module_list_block1 {
    width: 60vw;
  }
  .window_module_list_block2 {
    width: 20vw;
  }
}
